<template>
  <div class="box">
    <span v-if="!hasProduct()">No products :/</span>
    <div v-for="(product, index) in getProductsInCart"  :key="index" class="box-item">
      <img src="product.image" alt="" class="item-thumb">
      <span class="item-name">{{ product.name }}</span><span> </span>
      <span class="item-price">${{ parseFloat(product.price).toFixed(2) }}</span>
      <div style="cursor:pointer;background-color:red;border-radius:5px;color:white;font-size:18px;height:30px;margin-top:4px" @click="remove(index)">Remove</div>
      
    </div>
    <div class="cart-info p-4" v-if="hasProduct()">
            <router-link to="/checkout">
        <btn btnColor="btn btn-small btn-info"
          @click.native="showPopupCart()">
          View cart
        </btn>
        </router-link>
      
      <span>Total: ${{ totalPrice() }}</span>
 
    </div>
    <div v-if="totalPrice() > 0">
           <PayPal
    :amount=totalPrice()
    currency="USD"
    :client="paypal"
    label= "label">
  </PayPal> <img src="../assets/pay.jpg" alt="" class="item-thumb"></div>
  </div>
</template>



<script>
import { mapGetters, mapActions } from 'vuex';
import btn from './Btn';
import PayPal from 'vue-paypal-checkout';

export default {
data() {
    return {
      paypal: {
        sandbox: 'sb-j51fx4955987@business.example.com',
        production: 'AQHS3unRiosis2alfsWX2XlWChU4xhuKSk_u1ULKf8HFGOMxAhG0wgpNHAU_Rj9h07Q2sW1QZo5j5p4J'
      },
      
    }
  },
  components: {
    btn, PayPal
  },
  methods: {
    ...mapActions([
      'showOrHiddenPopupCart',
      'removeProduct',
      'addProduct',
      'getAllProducts'
    ]),
    hasProduct() {
      return this.getProductsInCart.length > 0;
    },
    totalPrice() {
     return parseFloat(this.getProductsInCart.reduce((current, next) =>
        parseFloat(current) + parseFloat(next.price), 0)).toFixed(2);
    },
    showPopupCart() {
      this.showOrHiddenPopupCart();
    },
        remove(index) {
      this.removeProduct(index);
    },
    addProductToCart(product) {
      console.log(this.getProductsInCart);
      this.addProduct(product);
      
    },
  },
  computed: {
    ...mapGetters([
      'getProductsInCart',
    ]),
  },
};
</script>

<style scoped>
  .box {
    width: 400px;
    height: auto;
    background-color: #FAFAFA;
    box-shadow: 0px 0px 10px rgba(73, 74, 78, 0.1);
    border-radius: 5px;
    box-sizing: border-box;
    padding: 1em .5em;
    position: absolute;
    z-index: 10;
    color:black;
  }

  .box:after {
    content: '';
    width: 30px;
    height: 30px;
    transform: rotate(45deg);
    background: inherit;
    position: absolute;
    top: -15px;
    right: 15px;
  }

  .box-item {
    width: 100%;
    height: 130px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 0 .5em;
    margin-top: .3em;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  .item-thumb {
    max-width: 70%;
    grid-column: 1/2;
    grid-row: 1/4;
    align-self: center;
  }

  .item-name {
    font-weight: normal;
    font-size:calc(12px + 1vw);
  }

  .item-amount {
    color: #ddd;
    font-size:calc(10px + .5vw);
  }

  .cart-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

</style>
