<template>
  <div class="checkout-box">
    <ul class="checkout-list">
      <transition-group name="fade">
      <li v-for="(product, index) in getProductsInCart" :key="index" class="checkout-product">
        <img :src="product.image" alt="" class="product-image">
        <h3 class="product-name">{{ product.name }}</h3>
        <span class="product-price">${{ parseFloat(product.price).toFixed(2) }} </span>
          <div style="cursor:pointer;background-color:red;border-radius:5px;color:white;padding:5px 10px" @click="remove(index)">Remove</div>
      </li>
      </transition-group>
    </ul>
    <div v-if="!hasProduct()" class="checkout-message">
      <h3>No products...</h3>
      <router-link to="./">Back to list of products</router-link>
    </div>
    <h3 class="total" v-if="hasProduct()">
      Total: ${{ totalPrice() }}
    </h3> 
  <div v-if="totalPrice() > 0">
           <PayPal
    :amount='totalPrice()'
    currency="USD"
    :client="paypal"
    :items="myItems"
    >
  </PayPal> <img src="../assets/pay.jpg" alt="" class="item-thumb"></div>
  </div>
  
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PayPal from 'vue-paypal-checkout';
export default {
data() {
    return {
      paypal: {
        sandbox: 'sb-j51fx4955987@business.example.com',
        production: 'AQHS3unRiosis2alfsWX2XlWChU4xhuKSk_u1ULKf8HFGOMxAhG0wgpNHAU_Rj9h07Q2sW1QZo5j5p4J'
      },
    }
  },
  components: {
   PayPal
  },

  computed: {
    ...mapGetters([
      'getProductsInCart',
    ]),
  },

  methods: {
    ...mapActions([
      'removeProduct',
    ]),
    hasProduct() {
      return this.getProductsInCart.length > 0;
    },
    totalPrice() {
      return parseFloat(this.getProductsInCart.reduce((current, next) =>
        parseFloat(current) + parseFloat(next.price), 0)).toFixed(2);
    },
    remove(index) {
      this.removeProduct(index);
    },
  },
};
</script>

<style scoped>
  .checkout-box {
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    margin: 50px auto;
    box-sizing: border-box;
    padding: 1em;
  }

  .checkout-list {
    padding: 0;
  }

  .checkout-product {
    display: grid;
    grid-template-columns: 1fr 3fr 2fr .5fr;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(73, 74, 78, 0.1);
    border-radius: 5px;
    list-style: none;
    box-sizing: border-box;
    padding: .8em;
    margin: 1em 0;
  }

  .checkout-product * {
    place-self: center;
  }
  .product-image {
    grid-column: 1/2;
    width: 50%;
  }

  .product-name {
    box-sizing: border-box;
  }

  .product-price {
    font-size: 1.2em;
    font-weight: bold;
  }

  .product-remove {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 0;
    background-color: #E0E0E0;
    color: #fff;
    cursor: pointer;
  }

  .total {
    font-size: 2em;
    font-weight: bold;
    align-self: flex-end;
  }

  .checkout-message {
    font-size: 1.5em;
  }

  .fade-enter-active, .fade-leave-active {
    transition: all .5s;
  }

  .fade-enter, .fade-leave-to {
    transform: translateX(-40px);
    opacity: 0;
  }
</style>
