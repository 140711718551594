     <template>
     <div class="row info">
       <div class="col pt-4">
     <h1 style="text-decoration:underline;text-decoration-color:yellow;font-size:calc(25px + 2vw); text-shadow: 2px 2px black;">Frequently Asked Questions</h1>
      <div class="row mb-5 py-4 px-4" >
          
        <div class="accordion col-12" role="tablist" style="border:0 !important;">
          <div style="text-align:left">
          <b-card no-body style="background-color:#FFFFFF00;border:none">
            <div
              block
              class="p-3"
              v-b-toggle.accordion-1
              style="font-size:calc(16px + 1.5vw);color:white;border-bottom:2px solid yellow; text-shadow: 2px 2px black;"
            >
              How are Sudspenser systems installed?
            </div>
            <b-collapse
            
              id="accordion-1"
              role="tabpanel"
            >
              <b-card-body style="background-color:white;border-bottom-left-radius:10px;border-bottom-right-radius:10px">
                <b-card-text
                  ><span style="font-size:calc(16px + .6vw);color:black;"
                    >Installation and operating instructions are provided for the MEGA Sudspenser</span
                  ></b-card-text
                >

              </b-card-body>
            </b-collapse>
          </b-card>
  
                    <b-card no-body  style="background-color:#FFFFFF00;border:none">
            <div
              block
              class="p-3"
              v-b-toggle.accordion-3
              style="font-size:calc(14px + 1.5vw);color:white;border-bottom:2px solid yellow;text-shadow: 2px 2px black;"
            >
              How much detergent is dispensed at each cycle and is it adjustable?
            </div>
            <b-collapse
            
              id="accordion-3"
              role="tabpanel"
            >
              <b-card-body style="background-color:white;border-bottom-left-radius:10px;border-bottom-right-radius:10px">
                <b-card-text
                  ><span style="font-size:calc(16px + .6vw);color:black;"
                    >Detergent manufacturers recommend 1-1/2 ounces for a normal wash. All SudSpenser models dispense that amount, flow then stops, you cannot overfill. For less detergent release wand lever before cycle ends. For more detergent complete a 2nd cycle.</span
                  ></b-card-text
                >

              </b-card-body>
            </b-collapse>
          </b-card>
                       <b-card no-body  style="background-color:#FFFFFF00;border:none">
            <div
              block
              class="p-3"
              v-b-toggle.accordion-4
              style="font-size:calc(14px + 1.5vw);color:white;border-bottom:2px solid yellow;text-shadow: 2px 2px black;"
            >
              What if I need to pre-spot clothes with detergent prior to wash?
            </div>
            <b-collapse
            
              id="accordion-4"
              role="tabpanel"
            >
              <b-card-body style="background-color:white;border-bottom-left-radius:10px;border-bottom-right-radius:10px">
                <b-card-text
                  ><span style="font-size:calc(16px + .6vw);color:black;"
                    >Very simple, remove wand from holder, press lever to spot clothes with the desired amount, return wand to holder.</span
                  ></b-card-text
                >

              </b-card-body>
            </b-collapse>
          </b-card>
                       <b-card no-body  style="background-color:#FFFFFF00;border:none">
            <div
              block
              class="p-3"
              v-b-toggle.accordion-5
              style="font-size:calc(14px + 1.5vw);color:white;border-bottom:2px solid yellow;text-shadow: 2px 2px black;"
            >
              Will Sudspenser systems work for fabric softener or bleach?
            </div>
            <b-collapse
            
              id="accordion-5"
              role="tabpanel"
            >
              <b-card-body style="background-color:white;border-bottom-left-radius:10px;border-bottom-right-radius:10px">
                <b-card-text
                  ><span style="font-size:calc(16px + .6vw);color:black;"
                    >Fabric softener is approved for use with all Sudspenser systems. While Sudspenser models will dispense bleach we do not recommend they be used for that, solely from a safety aspect.</span
                  ></b-card-text
                >

              </b-card-body>
            </b-collapse>
          </b-card>
                       <b-card no-body  style="background-color:#FFFFFF00;border:none">
            <div
              block
              class="p-3"
              v-b-toggle.accordion-6
              style="font-size:calc(14px + 1.5vw);color:white;border-bottom:2px solid yellow;text-shadow: 2px 2px black;"
            >
              I need a longer dispensing hose than the 5’ supplied?
            </div>
            <b-collapse
            
              id="accordion-6"
              role="tabpanel"
            >
              <b-card-body style="background-color:white;border-bottom-left-radius:10px;border-bottom-right-radius:10px">
                <b-card-text
                  ><span style="font-size:calc(16px + .6vw);color:black;"
                    >While standard models feature a 5’ or 6’ dispensing hose contact the factory to request special features i.e. additional length hose, lower or higher capacity dispensing volumes. We will strive to design a system to meet your requirements.</span
                  ></b-card-text
                >

              </b-card-body>
            </b-collapse>
          </b-card>
                       <b-card no-body  style="background-color:#FFFFFF00;border:none">
            <div
              block
              class="p-3"
              v-b-toggle.accordion-7
              style="font-size:calc(14px + 1.5vw);color:white;border-bottom:2px solid yellow;text-shadow: 2px 2px black;"
            >
              What is the SudSpenser warranty?
            </div>
            <b-collapse
            
              id="accordion-7"
              role="tabpanel"
            >
              <b-card-body style="background-color:white;border-bottom-left-radius:10px;border-bottom-right-radius:10px">
                <b-card-text
                  ><span style="font-size:calc(16px + .6vw);color:black;"
                    >Each SudSpenser system is warranted against material or labor defects for six months from date of shipment, specifics are stated in the installation and operating instructions.
</span
                  ></b-card-text
                >

              </b-card-body>
            </b-collapse>
          </b-card>
        </div></div>
      </div>
      </div></div>
      </template>

<script>
export default {
    name: 'Faq',
}
</script>

<style scoped>
b-card{
    border:none !important;
}
.info {
  background-image: linear-gradient(to right, #2e3192,#0059a9,#007cc2,#1b1464); 
  color:white !important;
  font-size:25px;
    border-bottom:3px solid #42b983;
      border-top:3px solid #42b983;
}
</style>