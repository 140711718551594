<template>
  <div class="container-fluid"><maskBg v-if="getVideos" @click.native="showVideos()"/>
    <div class="row home"><div class="mh-100 pl-4" style="width:100%;background-image: linear-gradient(to left, rgba(255,0,0,0), white);">
    <div class="col-12 col-xl-6 pt-xl-5"  style="text-align:left;">
      <img class="pt-xl-5 pt-3 pb-xl-3 ml-xl-3" alt="Vue logo" style="width:calc(100px + 15vw)" src="../assets/suds.svg"><h1  style="font-size:calc(6px + 2vw);padding-left:10px;color:#0059a9"><span style="color:#000000e3;font-size:calc(2px + 6vw);font-weight:700"><span>THE</span> Laundry Detergent Dispenser</span><br><span><span class="d-xl-block d-lg-block">The much needed accessory since the advent of liquid laundry detergent.</span></span><br></h1>
    
</div>
    <div class="col">

          
    
    </div></div>
    </div>
    
        <div class="row info p-5 h-100">
          <div v-if="videosOn" class="col-12">
<popupvideo  />
          </div>
          <div v-if="videosOn == false" class="col-xl-auto col-lg-12 mt-5">
            
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/ExP_zdxMirs" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
  <div  v-if="videosOn == false"  class="col" style="font-size:calc(18px + 2vw);text-align:left; text-shadow: 2px 2px black;text-align:center">
    <span style="font-size:calc(20px + 3vw);color:#fcee21">Watch</span> how simple <span style="color:#42b983;font-size:calc(20px + 3vw);">Sudspenser</span> is!
    <button @click="videosOn = !videosOn" class="ml-xl-4 px-4" style="background-color:#fcee21;border-radius:10px;font-size:calc(18px + 1.5vw)">View more videos</button>
    <p class="mt-3" style="font-size:calc(16px + .8vw);color:#FFFFFF;text-align:left">Pure and simple the MEGA SUDSPENSER measures the liquid detergent for a normal wash, then with an easy press of the lever accurately and quickly dispenses the detergent into the washer.  It doesn’t get any easier than that!</p>

<p style="font-size:calc(16px + .8vw);text-align:left">No lifting containers, no pouring, or squinting to see the detergent level markings on the cap, no overuse of detergent or wasting $ needlessly. Leave the measuring cups in the kitchen.</p>
  </div>

    </div>

     <div class="row p-4 mt-5 mb-5" style="color:#000000e3">

<div class="col-xl col-12 pl-xl-5 pt-xl-0 pt-5 py-2"><h1 style="font-size:calc(40px + 1.5vw);text-decoration:underline;text-decoration-color: #42b983;">Mega Sudspenser</h1>
<h4>Includes a 256 oz large capacity, hinged top, storage container</h4>
                <img
                  class="img-fluid mh-50 p-xl-1"
                  style="margin-top:7px;margin-bottom:5px; max-height: 500px;"
                  src="../assets/spensy.jpg"
                />
                <div>
                <p style="font-size:24px" class="pt-3">Designed for the high volume user, the Mega system provides enough storage capacity for up to 160 wash cycles before refilling. Large flip open top makes refilling a snap. May be conveniently be located along-side or behind washer. </p>
                 <div  style="font-size:20px" class="pt-3">
                   <h3>Includes:</h3>

                   <li>Auto-Measure Pump actuated system</li>
<li>HDPE flip top white container (12” length x 8” width x 12” height)</li>
<li>AC 120/1/60 Continuous Duty Adapter</li>
<li>Dispensing wand & wand actuator.</li>
<li>Adhesive backed wand holster for washer mounting.</li>
<li>5' Length clear dispense detergent tubing.</li>
<li>Installation and operating Instructions. [<a href="instructions.pdf">Download PDF</a>]</li>
                  </div>
                </div>
            <div class="pt-2" style="font-size:calc(14px + 1vw)">$129.95</div>
       <div style="font-size:calc(10px + .5vw);margin-top:-10px;">+ $20 Shipping</div>
      <button btnColor="btn btn-large btn-sucess" class="ml-xl-4 px-4" style="background-color:#fcee21;border-radius:10px;font-size:calc(18px + 1.5vw)"
          @click="addProductToCart(getAllProducts[2])">
        Add to Cart
      </button>
</div>
     </div>
     <FAQ />
<div class="row mb-4" style="font-family:'Pragati Narrow'"><div class="col-12 mt-4"><h1 style="font-size:calc(25px + 2vw);text-decoration:underline;text-decoration-color:#42b983;">Contact Us</h1></div><div class="col-12 col-xl pt-5">
  
<div style="font-size:calc(16px + 1.2vw);">Sudspenser Solutions
<div>26 Wildwood Forest Ln</div>
<div>Mills River, NC 28759</div></div><br><br>

<div style="font-size:calc(20px + 1vw)">Feel free to also contact us via:
<div><span style="color:#42b983;">Phone:</span> 315-706-7837</div>
<div><span style="color:#42b983;">Email:</span> mail@sudspenser.com</div></div>
</div><!--div class="col-12 col-xl-7 p-2">
  

  <form class="gform pure-form pure-form-stacked" method="POST" data-email="example@email.net"
  action="https://script.google.com/macros/s/AKfycbxURu1zNcnoJiexKTuH3yVA5xnAtVLAuI84UMhs5g/exec">


    <div class="form-elements">
      <fieldset class="pure-group p-2">
        <div><label for="name" style="font-size:25px;margin-right:5px;">Name: </label></div>
        <input  style="font-family:'Pragati Narrow';width:70%;font-size:30px;text-align:center" id="name" name="name" />
      </fieldset>

      <fieldset class="pure-group">
        <div><label for="email" style="font-size:25px;margin-right:5px;">Email:</label></div>
        <input style="font-family:'Pragati Narrow';width:70%;font-size:30px;text-align:center" id="email" name="email" type="email" value=""
        required />
      </fieldset>


      <fieldset class="pure-group p-2">
        <div><label for="message" style="font-size:25px;margin-right:5px;">Message: </label></div>
        <textarea style="font-family:'Pragati Narrow';width:70%;font-size:30px" id="message" name="message" rows="4"
        placeholder="Tell us what's on your mind..."></textarea>
      </fieldset>


      <fieldset class="pure-group honeypot-field">
        <label for="honeypot">To help avoid spam, utilize a Honeypot technique with a hidden text field; must be empty to submit the form! Otherwise, we assume the user is a spam bot.</label>
        <input id="honeypot" type="text" name="honeypot" value="" />
      </fieldset>

      <button style="color:black;background-color:#42b983;border-radius:10px;font-size:calc(18px + .7vw)" class="button-success pure-button button-xlarge pl-3 pr-4 pb-1 pt-2 mt-3">
        <i class="fa fa-paper-plane"></i>&nbsp;Send</button>
    </div>

    <div class="thankyou_message" style="display:none;text-align:center;color:red">
      <h2><em>Thanks</em> for contacting us!
        We will get back to you soon!</h2>
    </div>

  </form>

  </div>-->
  
  
  </div>


</div>
</template>

<script>
// @ is an alias to /src
import FAQ from '../components/FAQ';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import popupvideo from '../components/Popupvideo';
import maskBg from '../components/Mask';

export default {
  name: 'Home',
  components: {FAQ,popupvideo,maskBg,
  },
  data(){

   return{
videosOn: false,
contactUs: false,
megaShow: false,
sudsShow: false,
miserShow: false
  }},
    computed: {
    ...mapGetters([
      'getAllProducts','getVideos', 'getProductsInCart'
    ]),
    },
    mounted(){
console.log(this.getAllProducts[1])
  },
  
    methods: {
    ...mapActions([
      'addProduct',
      'currentProduct',
      'showOrHiddenVideos'
    ]),
        showVideos() {
      this.showOrHiddenVideos();
    },
    addProductToCart(product) {
      console.log(this.getProductsInCart);
      this.addProduct(product);
      
    },
    }
}
</script>

<style scoped>
.info {
  background-image: linear-gradient(to right, #2e3192,#0059a9,#007cc2,#1b1464); 
  color:white !important;
  font-size:25px;
    border-bottom:3px solid #42b983;
      border-top:3px solid #42b983;
}

h1{
  font-weight:2000 !important;
  font-family:'Pragati Narrow';
  }
.home{
  background-image: url('../assets/measure.jpg');
  background-repeat: no-repeat;
  background-size:cover;
  background-attachment:scroll;
  background-position-y: -80px;
  height:46vw;
}
.honeypot-field {
  display: none;
}


.cart2{
  width:75%;
  margin-right:10%;
  margin-left:10%;
}
</style>
