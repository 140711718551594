import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import CartCheckout from '../components/CartCheckout';
import Product from '../components/Product';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: CartCheckout,
  },
  {
    path: '/product-details',
    name: 'Product',
    component: Product,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splittings
    // this generates a separate chunk (about.[hash].js) for this routes
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
