<template>

    <div class="row p-2">
      <div class="col-12">
                    <div class="row pb-4 mb-4">
<div class="col-12 col-lg p-2" ><button style="background-color:#fcee21;border-radius:10px;font-size:calc(13px + 1vw);color:black" @click="video=0">Sudspenser in Action</button></div>
<div class="col-12 col-lg p-2 " ><button style="background-color:#fcee21;border-radius:10px;font-size:calc(13px + 1vw);color:black" @click="video=2">History of Laundry</button></div>
<div class="col-12 col-lg p-2 " ><button style="background-color:#fcee21;border-radius:10px;font-size:calc(13px + 1vw);color:black" @click="video=3">Unpacking and Installing</button></div>
  </div>
      </div>
      <div class="col-12">

   <div class="row" v-if="video == 0">
     <div class="col-12 col-xl-auto">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/ExP_zdxMirs" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
       </div>
       <div class="col-12 col-xl p-5"><h1>Sudspenser being used</h1>
       <h2>This video shows the pump-actuated Sudspenser dispensing the perfect amount of detergent into top load washer without the usual hassle.</h2>
       </div>
       
       </div> 
           
   <div class="row" v-if="video == 2"><div class="col-auto">
                   <iframe width="560" height="315" src="https://www.youtube.com/embed/WEcoRE89Ecc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

       </div>
       <div class="col p-5"><h1>Great Moments in Laundry History</h1>
       <h2>Learn about the revolution in laundry</h2>
       </div>
       
       </div>   
   <div class="row" v-if="video == 3"><div class="col-auto">
<iframe width="560" height="315" src="https://www.youtube.com/embed/PH-UNwTLawY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
       </div>
       <div class="col p-5"><h1>Unpacking and Installation</h1>
       <h2>Learn how to unpack and install your Sudspenser once you receive it.</h2>
       </div>
       
       </div>   


  </div>
  
  </div> 
</template>



<script>
import { mapGetters, mapActions } from 'vuex';

export default {
data() {
    return {
      paypal: {
        sandbox: 'sb-j51fx4955987@business.example.com',
        production: 'AQHS3unRiosis2alfsWX2XlWChU4xhuKSk_u1ULKf8HFGOMxAhG0wgpNHAU_Rj9h07Q2sW1QZo5j5p4J'
      },
      video: 0,
      videoName: ['Sudspenser Video', 'Sudspenser Commercial']
      
    }
  },
  methods: {
    ...mapActions([
      'showOrHiddenPopupCart',
      'removeProduct',
    ]),
    hasProduct() {
      return this.getProductsInCart.length > 0;
    },
    totalPrice() {
     return parseFloat(this.getProductsInCart.reduce((current, next) =>
        parseFloat(current) + parseFloat(next.price), 0)).toFixed(2);
    },
    showPopupCart() {
      this.showOrHiddenPopupCart();
    },
        remove(index) {
      this.removeProduct(index);
    },
    increment(){
      if(this.video<=1){
      this.video++;
      }
    },
      decrement(){
      if(this.video>=1){
      this.video--;
    }
  }
  },
  computed: {
    ...mapGetters([
      'getProductsInCart',
    ]),
  },
};
</script>

<style scoped>

</style>
